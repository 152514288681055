import { render, staticRenderFns } from "./returnurl.vue?vue&type=template&id=4f15f8fa&scoped=true&"
import script from "./returnurl.vue?vue&type=script&lang=js&"
export * from "./returnurl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f15f8fa",
  null
  
)

export default component.exports