<template>
  <div />
</template>

<script>
export default {
  name: 'ReturnURL',
  created() {
    if (window.isAndroidWebView()) {
      window.AndroidCallback.onSubscriptionCallback('success')
      return
    }

    if (window.isWebKit()) {
      window.webkit.messageHandlers.refreshUserSubInfo.postMessage('success')
      return
    }

    window.location.href = 'https://www.onechannel.one'
  }
}
</script>

<style lang="scss" scoped>

</style>
